import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAppSelector } from "../store/redux.hooks";
import ProtectedRoute, {
  AuthRouteCheck,
} from "../auth/ProtectedRoute";
import { getRole } from "constant";
const CarrierManagementView = lazy(() => import("pages/carrierManagement/CarrierManagementView"));
const TermsView = lazy(() => import("pages/terms/TermsView"));
const UserSettingView = lazy(() => import("pages/userSetting/UserSettingView"));
const EditUserView = lazy(() => import("pages/userManagement/editUser/EditUserView"));
const UserDetailView = lazy(() => import("pages/userManagement/detail/UserDetailView"));
const UserManagementView = lazy(() => import("pages/userManagement/UserManagementView"));
const LoginView = lazy(() => import("../pages/login/LoginView"));
const FileManagementView = lazy(() => import("../pages/fileManagement/FileManagementView"));
const DeletedFilesView = lazy(() => import("../pages/fileManagement/deletedFile/DeletedFilesView"));
const ErrorPage = lazy(() => import("../pages/error/ErrorView"));
const SignUpView = lazy(() => import("../pages/signUp/SignUpView"));
const FaqView = lazy(() => import("../pages/faq/FaqView"));
/**
 * Component that defines all the routes for the website
 */
const CarrierPortalRoute = () => {

  const { loginDetails } = useAppSelector((state: any) => state.auth);

  return (
    <Router basename="/">
      <Routes>
        {/* LoginView route */}
        <Route
          path="/"
          element={
            <AuthRouteCheck userDetails={loginDetails}>
              <Suspense
                fallback={
                  <div className="graph-loader  d-flex justify-content-center align-items-center">
                    <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                }
              >
                <LoginView />
              </Suspense>
            </AuthRouteCheck>
          }
        />
        <Route
          path="/signup"
          element={
            <AuthRouteCheck userDetails={loginDetails}>
              <Suspense
                fallback={
                  <div className="graph-loader  d-flex justify-content-center align-items-center">
                    <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                }
              >
                <SignUpView />
              </Suspense>
            </AuthRouteCheck>
          }
        />
        <Route
          path="/faq"
          element={
            <Suspense
              fallback={
                <div className="graph-loader  d-flex justify-content-center align-items-center">
                  <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              }
            >
              <FaqView />
            </Suspense>
          }
        />

        <Route
          path="/terms-conditions"
          element={
            <Suspense
              fallback={
                <div className="graph-loader  d-flex justify-content-center align-items-center">
                  <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              }
            >
              <TermsView />
            </Suspense>
          }
        />

        {/* Protected routes for application panel*/}

        <Route path="/" element={<ProtectedRoute role={[getRole["superAdmin"], getRole["admin"], getRole["user"]]} />} >
          <Route path="/settings" key={"user setting"} element={<UserSettingView />} />
          <Route path="/terms" key={"Terms & Conditions"} element={<TermsView isLoggedin={true} />} />
        </Route>

        <Route path="/" element={<ProtectedRoute role={[getRole["superAdmin"], getRole["admin"]]} />} >
          <Route path="/user-management-view/:userId/:scac?" element={<UserDetailView />} />
          <Route path="/user-management-edit/:user_id/:scac?" element={<EditUserView />} />
        </Route>

        <Route path="/" element={<ProtectedRoute role={[getRole["superAdmin"]]} />} >
          {/* DashboardView route not accessible for regional Manager*/}
          <Route path="/carriers" key={"carriers list"} element={<CarrierManagementView />} />
          <Route path="/file/list/:scac" key={"file list"} element={<FileManagementView />} />
          <Route path="/file/trash/:scac" key={"trash file list"} element={<DeletedFilesView />} />
          <Route path="/user-management/:scac" key={"user management"} element={<UserManagementView />} />
        </Route>
  
        <Route path="/" element={<ProtectedRoute role={[getRole["admin"]]} />} >
          {/* user management route */}
          <Route path="/user-management" key={"user management"} element={<UserManagementView />} />
        </Route>

        <Route path="/" element={<ProtectedRoute role={[getRole["admin"], getRole["user"]]} />} >
          <Route path="/file/list" key={"file list"} element={<FileManagementView />} />
          <Route path="/file/trash" key={"trash file list"} element={<DeletedFilesView />} />
        </Route>

        {/* ErrorPage route */}
        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <div className="graph-loader  d-flex justify-content-center align-items-center">
                  <div className="spinner-border  spinner-ui d-flex justify-content-center align-items-center">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              }
            >
              <ErrorPage />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
};

export default CarrierPortalRoute;
