import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenHeader, getErrorMessage, isCancelRequest } from "../../utils";
import userServices from "./userServices";
import { UserStateInterface } from "./userInterface";
import { toast } from "react-toastify";
import { getCarrierList } from "store/carrier/carrierSlice";

const initialState: UserStateInterface = {
  isSuccess: false,
  isError: false,
  isLoading: false,
  data: null,
  error: null,
  userProfile: null,
  userList: null,
  isUserListLoading: false,
  isLoadingDeleteUser: false,
  isUserListByIdLoading: false,
  singleUserDetail: null,
  updateProfile:null,
  uploadProfilePic:null,
};

export const getUserDetails = createAsyncThunk(
  "get/getUserDetails",
  async (_, thunkApi) => {
    try {
      return await userServices.getUserDetails(getTokenHeader());
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getUserList = createAsyncThunk(
  "get/user/list",
  async (userData: any, thunkApi: any) => {
    try {
      let res = await userServices.getUserListApi(userData, getTokenHeader());
      return res
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getUserDetailById = createAsyncThunk(
  "get/user/by-id",
  async (data: any, thunkApi: any) => {
    try {
      const res = await userServices.getUserDetailById(data, getTokenHeader());
      return res
    } catch (error: any) {
      const message: any = getErrorMessage(error);

      return thunkApi.rejectWithValue(message);
    }
  }
);

export const addUpdateUser = createAsyncThunk(
  "create/update/user",
  async ({ data, userPayLoad, handleCloseModal, navigate, scac }: any, thunkApi: any) => {
    try {
      const res = await userServices.addUpdateUserApi(data, getTokenHeader());
      if(userPayLoad?.isCarrierList){
        await thunkApi.dispatch(getCarrierList(userPayLoad));
      }
      else if (userPayLoad?.isDetail) {
        await thunkApi.dispatch(getUserDetailById({
          user_id: userPayLoad?.userId,
        }));
      } else if (navigate) {
        navigate(`/user-management/${scac ?? ""}`)
      } else {
        await thunkApi.dispatch(getUserList(userPayLoad));
      }

      if(handleCloseModal){
         handleCloseModal()
      }

      toast.success(res?.message)
      return res
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const deleteUser = createAsyncThunk("delete/user",
  async ({ data, userPayLoad }: any, thunkApi: any) => {
    try {
      const res = await userServices.deleteUser(data, getTokenHeader());
      if(userPayLoad?.isCarrierList){
        await thunkApi.dispatch(getCarrierList(userPayLoad));
      }
      else if (userPayLoad?.isDetail) {
        userPayLoad.navigate(`/user-management/${userPayLoad?.scac ?? ""}`)
      } else {
        await thunkApi.dispatch(getUserList(userPayLoad));
      }
      toast.success(`User deleted  successfully`)
      return res
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateProfileApi = createAsyncThunk(
  "update/updateProfileApi",
  async (userData: any, thunkApi) => {
    try {
      const response =await userServices.updateProfileApi(userData?.userPayload, getTokenHeader());
      toast.success(response?.message);
      userData?.formik?.resetForm();
      await thunkApi.dispatch(getUserDetails());
     
      return response
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const uploadProfilePic = createAsyncThunk(
  "update/uploadProfilePic",
  async (userData: any, thunkApi) => {
    try {
      await userServices.updateProfilePicApi(userData, getTokenHeader());
      !userData?.isLogo && toast.success("Profile picture uploaded successfully");
    } catch (error: any) {
      const message: any = getErrorMessage(error);
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const userReducer = createSlice({
  name: "user-details",
  initialState,
  reducers: {
    resetUserData: () => initialState,
    resetSingleUserData: (state) => {
      state.singleUserDetail = null
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
        state.userProfile = null;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.userProfile = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, _) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.userProfile = null;
      })
      .addCase(getUserList.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
        state.isUserListLoading = true;
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.userList = action?.payload;
        state.isUserListLoading = false
      })
      .addCase(getUserList.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isUserListLoading = isCancelRequest(action?.payload);
      })
      .addCase(addUpdateUser.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
        state.isUserListLoading = true;
      })
      .addCase(addUpdateUser.fulfilled, (state, _) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isUserListLoading = false
      })
      .addCase(addUpdateUser.rejected, (state, _) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isUserListLoading = false
      })
      .addCase(getUserDetailById.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
        state.isUserListByIdLoading = true;
        state.singleUserDetail = null
      })
      .addCase(getUserDetailById.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isUserListByIdLoading = false;
        state.singleUserDetail = action?.payload;
      })
      .addCase(getUserDetailById.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isUserListByIdLoading = false
      })
      .addCase(deleteUser.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
        state.isLoadingDeleteUser = true;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.isLoadingDeleteUser = false;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.isLoadingDeleteUser = false
      })
      .addCase(updateProfileApi.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
        state.updateProfile = null;
      })
      .addCase(updateProfileApi.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.updateProfile = action?.payload;
      })
      .addCase(updateProfileApi.rejected, (state, _) => {
        state.isSuccess = false;
        state.isLoading = false;
        state.updateProfile = null;
      })
      .addCase(uploadProfilePic.pending, (state) => {
        state.isSuccess = false;
        state.isLoading = true;
      })
      .addCase(uploadProfilePic.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isLoading = false;
        state.uploadProfilePic = action?.payload;
      })
      .addCase(uploadProfilePic.rejected, (state, _) => {
        state.isSuccess = false;
        state.isLoading = false;
      })
  },
});

export const { resetUserData, resetSingleUserData } = userReducer.actions;
export default userReducer.reducer;
