import axios from 'axios';
import { toast } from 'react-toastify';

// Method for fetching user details.
const getUserDetails = async (token: any): Promise<any> => {
    try {
        // Send a GET request to the "/user-profile" endpoint with the provided token.
        return (await axios.get("/user-profile", token)).data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
};

const getUserListApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post("/user/list", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const addUpdateUserApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post("/add/update/user", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}


const getUserDetailById = async (data: any, token: any) => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.post("/single/user/deatil/user", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}

const deleteUser = async (data: any, token: any) => {
    try {
        // Send a POST request to the "get-single-user detail" endpoint with the provided data and token.
        const response = await axios.post("/delete/user", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
};

// Method for updating the user's profile.
const updateProfileApi = async (data: any, token: any) => {
    try {
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        const response = await axios.post("/profile-update", data, token);
        // Return the response data.
        return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
};

const updateProfilePicApi = async (data: any, token: any) => {
    try {
        const response = await axios.post("blob/sas/token",
            {
                "is_logo": data?.isLogo,
                "fileSize": data?.imageFile?.size || '',
                "fileMimeType": data?.imageFile?.type || ''
            }, token
        );
        if (response?.data?.status && response?.data?.data?.sasToken) {
            try {
                const file = data?.imageFile;
                const fileName = `${Date.now()}-${file.name}`;
                const uploadResult: any = await axios({
                    method: "put",
                    url: `${response?.data?.data?.url}/${response?.data?.data?.profilePath}/${fileName}?${response?.data?.data?.sasToken}`,
                    data: file,
                    headers: {
                        "Content-Type": "application/octet-stream",
                        Accept: "application/json, text/plain, */*",
                        "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
                        "Cache-Control": "no-cache",
                        Connection: "keep-alive",
                        Origin: "https://appdev.greensight.ai",
                        Pragma: "no-cache",
                        "Sec-Fetch-Dest": "empty",
                        "Sec-Fetch-Mode": "cors",
                        "Sec-Fetch-Site": "cross-site",
                        "User-Agent":
                            "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36",
                        deniedCancle: "true",
                        "file-name": file?.name,
                        "file-type": file?.type,
                        "sec-ch-ua":
                            '"Not_A Brand";v="8", "Chromium";v="120", "Google Chrome";v="120"',
                        "sec-ch-ua-mobile": "?0",
                        "sec-ch-ua-platform": '"Linux"',
                        "x-ms-blob-type": "BlockBlob",
                        "x-ms-date": new Date().toUTCString(),
                        "x-ms-version": "2023-08-03",
                    },
                });

                const headersDto = token
                headersDto.headers['Content-Type'] = 'text/plain'
                await axios.post("update/profile/status", {
                    "fileName": fileName,
                    "updateValues": response?.data?.data?.updateValues,
                    "is_logo": data?.isLogo,
                }, headersDto

                );
                return uploadResult;
            } catch (error: any) {
                return error;
            }
        } else if (!response?.data?.status && !response?.data?.data?.sasToken) {
            toast.error(response?.data?.message);
            return response?.data;
        }
        return response?.data;
        // Send a POST request to the "profile-update" endpoint with the provided data and token.
        // const response = await axios.post("user-profile-image", data, token);
        // Return the response data.
        // return response?.data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
}


const UserServices = {
    getUserDetails,
    getUserListApi,
    addUpdateUserApi,
    getUserDetailById,
    deleteUser,
    updateProfileApi,
    updateProfilePicApi
}

// Export the UserServices class for use in other parts of the application.
export default UserServices;
