import CustomModal from 'component/DailogBox/CustomModal';
import { Form } from "react-bootstrap";
import InputField from "component/forms/input";
import ButtonComponent from 'component/forms/button';
import ContactUsFromController from './contactUsController';
import Loader from 'component/loader/Loader';
import ErrorMessaage from 'component/forms/errorMessaage';
import CKEditorComponent from 'component/CkEditor/Editor';
import { Label } from 'reactstrap';
import Heading from "component/heading";
import ImageComponent from "component/images";
const ContactUsView = ({ showContactUs, setShowContactUs }: any) => {

    // imported functions and variables from LoginController
    const {
        formik,
        isLoading
    } = ContactUsFromController(setShowContactUs);
    
    return (
        <div>
            <Loader isLoading={[isLoading]}/>
            <CustomModal
                show={showContactUs}
                isToggle={() => {
                    setShowContactUs(!showContactUs);
                    formik.resetForm();
                }}
                modalClass="contactUS"
                modalHeader="Contact Us"
                handleClose={() => {
                    setShowContactUs(false);
                    formik.resetForm();
                }}
            >
                <div className='d-flex gap-3 p-3'>
                    <Form className='w-100'
                    onSubmit={formik.handleSubmit}
                     >
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <InputField
                                type="text"
                                name="subject"
                                Id="Subject"
                                placeholder="Enter Subject"
                                label="Subject"
                                value={formik.values.subject}
                                isRequired={true}
                                onChange={formik.handleChange}
                            />
                            <ErrorMessaage
                                touched={formik.touched.subject}
                                errors={formik.errors.subject}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Label for="Message">Message<span className='text-danger'>*</span></Label>
                            <CKEditorComponent  value={formik.values.message}
                                onChange={(data:any)=> formik.setFieldValue("message", data)}/>
                             <ErrorMessaage
                                touched={formik.touched.message}
                                errors={formik.errors.message}
                            />
                        </Form.Group>
                        <div className="d-flex gap-2">
                            <ImageComponent path='/images/message-info.svg' className="pe-0 mt-1" />
                            <Heading level="3" className="font-xxl-16 font-14 fw-medium mb-0 lh-base">In case of any questions, please contact <span className="fw-semibold"><a href="mailto:pepsico@scope23.ai" className="text-decoration-none text-dark">"pepsico@scope23.ai"</a></span></Heading>
                        </div>
                        <div className='d-flex justify-content-end gap-2 mt-4'>
                            <ButtonComponent text="Cancel" btnClass='gray-btn rounded-2 px-4 py-2 font-14' type="button" onClick={() => {setShowContactUs(false); formik.resetForm();}} />
                            <ButtonComponent text="Submit" btnClass='btn-deepgreen px-4 py-2 font-14' type="submit" />
                        </div>
                    </Form>
                </div>
            </CustomModal>
        </div>
    )
}

export default ContactUsView