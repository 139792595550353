import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
import { AuthDataInterface } from "./authDataInterface";
import { getErrorMessage } from "utils";
import { resetCommonData } from "../common/commonSlice"
import { resetFileData } from "../file/fileSlice"

/**
 * Initial state for the authentication
 */
const initialState: AuthDataInterface = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    isAuthLoginLoading: false,
    isOtpVerifyLoading: false,
    message: null,
    loginDetails: null,
    isOtp: false,
    otpSuccess: false,
    otpError: false,
    userPassword: null,
    transportProviderData:null
}

// Async Thunks

// Login slice
export const loginPost = createAsyncThunk("post/login", async (payload: any, thunkApi: any) => {
    try {
        const response = await authService.authLoginPost(payload?.userPayload);
        if (response?.data?.profile?.phone_number) {
            payload.setShow(true)
        } else {
            return response;
        }
    } catch (error: any) {
        const message: any = getErrorMessage(error)
        return thunkApi.rejectWithValue(message);
    }
});

export const setPassword = createAsyncThunk("password", async (status: string) => {
    return status
})


// Verify OTP slice
export const otpPost = createAsyncThunk("post/otp", async (useData: any, thunkApi: any) => {
    try {
        const response = await authService.authPostOtp(useData);
        return response;
    } catch (error: any) {
        const message: any = getErrorMessage(error)
        return thunkApi.rejectWithValue(message);
    }
});

// Resend OTP slice
export const resendOtpPost = createAsyncThunk("resendPost/otp", async (useData: any, thunkApi: any) => {
    try {
        const res = await authService.resendPostOtp(useData);
        return res;
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

// Verify Sigup slice
export const signUp = createAsyncThunk("post/signUp", async (useData: any, thunkApi: any) => {
    try {
        const response = await authService.authSignUp(useData);
        return response;
    } catch (error: any) {
        const message: any = getErrorMessage(error)
        return thunkApi.rejectWithValue(message);
    }
});

export const getTransportProvider = createAsyncThunk("get/transport/provider", async (_, thunkApi: any) => {
    try {
        const response = await authService.transportProviderListApi();
        return response;
    } catch (error: any) {
        const message: any = getErrorMessage(error)
        return thunkApi.rejectWithValue(message);
    }
});

// Logout slice
export const logoutPost = createAsyncThunk("post/logout", async (_, thunkApi) => {
    try {
        thunkApi.dispatch(resetAuth())
        thunkApi.dispatch(resetFileData());
        thunkApi.dispatch(resetCommonData());
        return localStorage.clear();
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
});

// Authentication Reducer
export const authDataReducer = createSlice({
    name: "auth-login",
    initialState,
    reducers: {
        resetAuth: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginPost.pending, (state: any, _: any) => {
                state.isAuthLoginLoading = true;
                state.isSuccess = false;
            })
            .addCase(loginPost.fulfilled, (state: any, action: any) => {
                state.isAuthLoginLoading = false;
                state.isSuccess = true;
                state.isOtp = action?.payload?.data?.otp || false;
                state.loginDetails = action.payload;
            })
            .addCase(loginPost.rejected, (state: any) => {
                state.isAuthLoginLoading = false;
                state.isSuccess = false;
            })
            .addCase(signUp.pending, (state: any, _: any) => {
                state.isAuthLoginLoading = true;
                state.isSuccess = false;
            })
            .addCase(signUp.fulfilled, (state: any, action: any) => {
                state.isAuthLoginLoading = false;
                state.isSuccess = true;
                state.isOtp = action?.payload?.data?.otp || false;
                state.loginDetails = action.payload;
            })
            .addCase(signUp.rejected, (state: any) => {
                state.isAuthLoginLoading = false;
                state.isSuccess = false;
            })
            .addCase(logoutPost.fulfilled, (state) => {
                state.loginDetails = null;
            })
            .addCase(otpPost.pending, (state) => {
                state.isOtpVerifyLoading = true;
                state.otpSuccess = false;
            })
            .addCase(otpPost.fulfilled, (state: any, action: any) => {
                state.isOtpVerifyLoading = false;
                state.otpSuccess = true;
                state.isOtp = false;
                state.loginDetails = action.payload;
            })
            .addCase(otpPost.rejected, (state: any) => {
                state.isOtpVerifyLoading = false;
                state.otpSuccess = false;
            })
            .addCase(resendOtpPost.pending, (state) => {
                state.isOtpVerifyLoading = true;
                state.otpSuccess = false;
            })
            .addCase(resendOtpPost.fulfilled, (state: any) => {
                state.isOtpVerifyLoading = false;
            })
            .addCase(resendOtpPost.rejected, (state: any) => {
                state.isOtpVerifyLoading = false;
            })
            .addCase(setPassword.fulfilled, (state: any, action: any) => {
                state.userPassword = action.payload;
            })
            .addCase(getTransportProvider.pending, (state: any, _: any) => {
                state.isLoading = true;
            })
            .addCase(getTransportProvider.fulfilled, (state: any, action: any) => {
                state.isLoading = false;
                state.transportProviderData = action.payload;
            })
            .addCase(getTransportProvider.rejected, (state: any) => {
                state.isLoading = false;
            })
    }

});

export const { resetAuth } = authDataReducer.actions;
export default authDataReducer.reducer;
