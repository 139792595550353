import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/redux.hooks';
import { logoutPost } from 'store/auth/authDataSlice';
import { useState } from 'react';

/**
 * 
 * @returns All the states and functions for DashboardView
 */

const SidebarController = () => {
    // Define constant 
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [showContactUs, setShowContactUs] = useState(false);
    const {isSidebarOpen } = useAppSelector((state: any) => state.commonData)
    const navigate = useNavigate()
    const { loginDetails } = useAppSelector((state:any) => state.auth);

    // Logout function
    const handleLogout = () => {
        dispatch(logoutPost() as any);
        navigate("/")
    }


    //  All the states and functions returned
    return {
        loginDetails,
        handleLogout,
        isSidebarOpen,
        dispatch,
        navigate,
        location,
        showContactUs, 
        setShowContactUs
    };
};

export default SidebarController;