import React from 'react'

interface SpinnerComponentProps {
    spinnerClass?: string;
  }

const Spinner : React.FC<SpinnerComponentProps> = ({spinnerClass}) => {
  return (
    <div className={`d-flex align-items-center gap-2 ${spinnerClass}`}>
        <div className="spinner-border spinner-ui">
          <span className="visually-hidden"></span>
    </div>
    </div>
  )
}

Spinner.defaultProps = {
    spinnerClass: ""
  }
export default Spinner