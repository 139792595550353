import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getTokenHeader, getErrorMessage, isCancelRequest } from "../../utils";
import carrierService from "./carrierService";
import { CarrierInterface } from "./carrierInterface";
import { toast } from "react-toastify";

const initialState: CarrierInterface = {
    isSuccess: false,
    isError: false,
    isLoading: false,
    message: null,
    isLoadingCarrierList: false,
    carrierListData: null,
    isLoadingCreateDb: false,
};

export const getCarrierList = createAsyncThunk("get/carrier/list", async (userData:any, thunkApi) => {
    try {
        return await carrierService.getCarriersApi(userData, getTokenHeader());
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
}
);

export const createCarrierDB = createAsyncThunk("create/carrier/database", async (userData:any, thunkApi) => {
    try {
        const res = await carrierService.createCarrierDbApi(userData, getTokenHeader());
        toast.success(`Database for ${userData?.scac} created successfully`)
        return res
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
}
);

export const carrierReducer = createSlice({
    name: "carrier-details",
    initialState,
    reducers: {
        resetUserData: () => initialState
    },

    extraReducers: (builder) => {
        builder
            .addCase(getCarrierList.pending, (state) => {
                state.isSuccess = false;
                state.isLoadingCarrierList = true;
                state.carrierListData = null;
            })
            .addCase(getCarrierList.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isLoadingCarrierList = false;
                state.carrierListData = action.payload;
            })
            .addCase(getCarrierList.rejected, (state, action) => {
                state.isSuccess = false;
                state.isLoadingCarrierList = isCancelRequest(action?.payload)
            })
            .addCase(createCarrierDB.pending, (state) => {
                state.isSuccess = false;
                state.isLoadingCreateDb = true;
            })
            .addCase(createCarrierDB.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isLoadingCreateDb = false;
            })
            .addCase(createCarrierDB.rejected, (state, action) => {
                state.isSuccess = false;
                state.isLoadingCreateDb = false;
            })
    },
    
});

export const { resetUserData } = carrierReducer.actions;
export default carrierReducer.reducer;