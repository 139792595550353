import React from 'react';
import { getImageUrl, handleProfileImage } from 'utils';

interface ImageProps {
  imageName?: string;
  alt?: string;
  className?: string;
  path?: string;
  handleImageError?: any;
  handleOnClick?: any;
  testid?: string;
  tooltipTitle?:string;
}

const Image: React.FC<ImageProps> = ({
  handleImageError=handleProfileImage,
  imageName,
  alt="pic",
  className,
  path="",
  handleOnClick,
  testid,
  tooltipTitle=""
}) => {
  return (
   <span data-toggle="tooltip" data-placement="top" title={tooltipTitle}>
   <img
      src={path ? getImageUrl(path) : require(`../../assets/images/${imageName}`)}
      alt={alt}
      data-testid={testid}
      style={{ paddingRight: "6px" }}
      className={className}
      onError={handleImageError}
      onClick={handleOnClick}
    />
    </span>
 
  );
};

export default Image;
