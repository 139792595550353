import HeaderController from "./headerController";
import ImageComponent from "component/images";
import { handleProfileImage } from "utils";
import ButtonComponent from "component/forms/button";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { openSidebar } from "store/common/commonSlice";
import Logo from "component/logo"

const Header = () => {
  // iporting all states and functions from dashboard controller
  const {
    userProfile,
    pageTitle,
    dispatch,
    isSidebarOpen
  } = HeaderController();

  return (
    <header data-testid="header-layout" className="dashboard_Header">
      <nav className="navbar navbar-expand-lg navbar-light py-0">
        <div className="container-fluid px-0">
          <div
            className="navbar-collapse d-sm-flex"
            id="navbarSupportedContent"
          >
            <div className="d-flex gap-3 me-auto mb-2 mb-sm-0 align-items-center">
              <ButtonComponent onClick={() => dispatch(openSidebar(!isSidebarOpen))} imagePath="/images/hamburger.svg" btnClass={`${isSidebarOpen ? "d-none" : "close-btn-sidebar p-0 border-0"}`} />
              {userProfile?.data?.carrierDetails?.carrier_scac && 
              <div className="d-flex align-items-center gap-2">
                <div className="lower-logo">
                  <Logo nameLength={4} path={userProfile?.data?.carrierDetails?.carrier_logo} name={userProfile?.data?.carrierDetails?.carrier_scac} />
                </div>
                  <div className="verticleLine"></div>
              </div>}


              <div className="mainTopTitles">
                <h1 className="mb-0 text-capitalize mb-0 fw-semibold">
                  {pageTitle}
                </h1>
                <div className="latest-update">
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-center rightTabs">
              <div className="d-flex align-items-center gap-4">
                <div className="d-flex gap-2">
                  <ImageComponent path='/images/pepsico-color.svg' className="pe-0" />
               
                </div>
                <div>
                  <ButtonDropdown isOpen={false} toggle={() => { }}>
                    <DropdownToggle caret>
                      <ImageComponent path="/images/header/notificationicon.svg" alt="notification Icon" className="pe-0" />

                      <span className="position-absolute top-0 start-100 translate-middle p-2 border border-light rounded-circle">
                        <span className="visually-hidden">New alerts</span>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu className={"action-btnlist flex-column gap-2 mt-1"}>
                      <DropdownItem header className="font-18 px-1 py-0 fw-bold text-start">Notifications</DropdownItem>
                      <DropdownItem divider />

                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </div>


              <div className="d-flex gap-3 align-items-center ">
                <div className="headerimg">
                  <ImageComponent
                    imageName="profile-img-auto.png"
                    path={userProfile?.data?.profile?.image}
                    handleImageError={handleProfileImage}
                    alt="user profile"
                  />
                </div>
                <div className="userHead_details d-none d-md-block">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <h3 className="font-16 mb-0 fw-semibold">
                      {userProfile?.data?.profile?.first_name}{" "}
                      {userProfile?.data?.profile?.last_name}
                    </h3>
                  </div>
                  <p className="mb-0 font-14 ">
                    {userProfile?.data?.profile?.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
