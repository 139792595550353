import CarrierPortalRoute from './routes/carrierPortalRoute';

const App = () => {
  return (
    <div data-testid="app-component">
        <CarrierPortalRoute />
    </div>

  );
}

export default App;
