import { useAppDispatch, useAppSelector } from '../../../store/redux.hooks';
import { useEffect } from 'react';
import { getUserDetails } from 'store/user/userSlice';

/**
 * 
 * @returns All the states and functions for DashboardView
 */

const HeaderController = () => {
    // Define constant 
    const dispatch = useAppDispatch()
    const {isSidebarOpen, pageTitle, isLoadingNotification, notificationDetail } = useAppSelector((state: any) => state.commonData)
    const { userProfile } = useAppSelector((state: any) => state.user)

    useEffect(() => {
        dispatch(getUserDetails())
    }, [dispatch])

    //  All the states and functions returned
    return {
        userProfile,
        pageTitle,
        isLoadingNotification,
        notificationDetail,
        dispatch,
        isSidebarOpen
    };
};

export default HeaderController;