import { useFormik } from 'formik';
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from '../../store/redux.hooks'
import { contactUs } from 'store/common/commonSlice';

/**
 * 
 * @returns all controllers for contact us page.
 */
const ContactUsFromController = (setShowContactUs:any) => {

    // Defined all stats and constants
    const dispatch = useAppDispatch();
    // Import data from auth selector
    const {  isLoading } = useAppSelector((state: any) => state.commonData);

    // used in formic to call login api

    const handleSubmit = (event: any) => {
        dispatch(contactUs({payload:{subject:event.subject,text:event.message}, setShowContactUs, formik}));
    };

// Define initial values for the form fields
const _Fields = {
    subject: '',
    message: '',
  };
    //schema for varification of email and password
    const schema = yup.object().shape({
        subject: yup
            .string()
            .max(250, 'Subject should not exceed more than 250 characters')
            .required("Subject should not be empty"),
        message: yup
            .string()
            .max(2000, 'Message should not exceed more than 2000 characters')
            .required("Message should not be empty"),
    });

    // formic to control login form
    const formik = useFormik({
        initialValues: _Fields,
        validationSchema: schema,
        onSubmit: handleSubmit,
    });

    // All the state and function return to LoginView
    return {
        formik,
        isLoading
    }
}

export default ContactUsFromController
