// CKEditorComponent.js
import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CKEditorComponent = ({ value, onChange,placeholder="Enter your text here..." }:any) => {
  return (
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          placeholder: "Enter Messge",
          toolbar: []
        }}
        onChange={(event:any, editor:any) => {
          const data = editor.getData();
          onChange(data);
        }}
      />
  );
};

export default CKEditorComponent;
