// Import necessary assets
import moment from "moment";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { imageToken, imageURL } from "./InterceptorApi";
import styles from '../scss/config/_variable.module.scss'
import { getRole } from "constant";
const CryptoJS = require("crypto-js");

export const currentYear = 2023;

//Get value from local storage or default to an empty string
export const getLocalStorage = (key: string) => {
  const localStorageData = localStorage.getItem(key);
  if (!localStorageData) {
    return "";
  }
  return JSON.parse(localStorageData);
};


// Function to handle error responses
export const getErrorMessage = (error: AxiosError): any => {
  const errorData: any = error?.response?.data;
  const errorMessage = errorData?.message
    ? errorData.message.toString()
    : "Something went wrong!";
  errorData && toast.error(errorMessage);
  return { code: error?.code, message: errorMessage };
};

// Helper function to get token header
export const getTokenHeader = () => {
  const userdata: any = getLocalStorage("loginDetails");

  let token: string = userdata?.token;

  return {
    headers: { Authorization: `Bearer ${token}` },

  };
};

export const getUserStatus = (status: any) => {
  if(status){
    return { name: "Active", color: "active" };
  }else{
    return { name: "Deactivated", color: "deactive" };
  }
};

// Function to prevent spaces in input for certain fields.
export const removeSpaceOnly = (e: any) => {
  const inputValue = e.target.value;
  const key = e.keyCode ? e.keyCode : e.which;

  // Allowing spaces only if the input value is not empty and not starting with a space
  if (inputValue.length === 0 && key === 32) {
    e.preventDefault();
  }
};

// Function to determine which sort icon to display
export const sortIcon = (
  key: string,
  col_name: string,
  order: string
): string => {
  if (col_name === key) {
    return order === "asc" ? "topactiveArrow.svg" : "downactiveArrow.svg";
  } else {
    return "disabledArrow.svg";
  }
};

export const bidSortIcon = (order: string) => {
  return order === "asc" ? "/images/orderUp.svg" : "/images/orderDown.svg";
};


export const capitalizeText = (str: string) => {
  return str
    ?.toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const formatNumber = (
  isDecimalNumber: boolean,
  number: any,
  place?: number,
  maxPlace?: number
) => {
  let numberData: any = "0";
  let decimalPoint = Math.pow(10, place ?? 1);
  if (number !== "N/A") {
    if (!isDecimalNumber) {
      numberData = Number.parseInt(number || 0)?.toLocaleString("en-US");
    } else {
      numberData = (
        Math.floor(Math.round(Number.parseFloat(number || 0) * decimalPoint)) / decimalPoint
      )?.toLocaleString("en-US", {
        minimumFractionDigits: place,
        maximumFractionDigits: maxPlace ?? place,
      });
    }
  }
  return numberData;
};


export const sortList = (list: any, colName: string, order: string) => {
  let name = colName;
  const newList = [...list]?.sort((a: any, b: any) => {
    if (order === "asc") {
      if (a[name] < b[name]) return -1;
      if (a[name] > b[name]) return 1;
      return 0;
    } else {
      if (a[name] > b[name]) return -1;
      if (a[name] < b[name]) return 1;
      return 0;
    }
  }
  );
  return newList;
};

export const avgList = (list: any, colName: string) => {
  return list?.reduce(function (acc: any, obj: any) { return acc + obj[colName]; }, 0) / list.length;
}
export const getStrokeOpacity = (type: string) => (type === "TRANSIT" ? 0 : 1);
export const getMapPath = (type: string) =>
  type === "TRANSIT"
    ? google.maps.SymbolPath.CIRCLE
    : google.maps.SymbolPath.FORWARD_OPEN_ARROW;
export const getMapTravelMode = (type: string) =>
  type === "Rail"
    ? google.maps.TravelMode.TRANSIT
    : google.maps.TravelMode.WALKING;

export const getMapIcon = (type: string) =>
  type === "TRANSIT"
    ? [
      {
        icon: {
          path: getMapPath(type),
          fillColor: (styles.limeGreen),
          fillOpacity: 1,
          scale: 2,
          strokeColor: (styles.limeGreen),
          strokeOpacity: 1,
        },
        offset: "0",
        repeat: "10px",
      },
    ]
    : null;


export const getOrder = (order: string) => (order === "desc" ? "asc" : "desc");


export const isCancelRequest = (res: any) => {
  return res?.code === "ERR_CANCELED" || res === "canceled";
}

export const formatDate = (date: any) => {
  return moment(date).format("DD MMM YYYY");
}

export const typeCheck = (
  cond: boolean,
  firstStatment: any,
  secondStatment: any
) => {
  return cond ? firstStatment : secondStatment;
};


export const ecryptDataFunction = (password: string) => {
  return CryptoJS.AES.encrypt(JSON.stringify(password), process.env.REACT_APP_EN_KEY).toString()
}

export const decryptDataFunction = (password: string) => {
  const bytes = CryptoJS.AES.decrypt(password, process.env.REACT_APP_EN_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const handleProfileImage = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  const target = e.target as HTMLImageElement;
  target.src = require("../assets/images/defaultError.png");
}

export const getImageUrl = (imageName: string) => {
  return imageURL + imageName + "?" + imageToken
}
export const isPermissionChecked = (data: any, identifier: any) => {
  for (let item of data) {
    if (item?.slug === identifier) {
      return item;
    }
    if (item?.child?.length > 0) {
      let childChecked: any = isPermissionChecked(item?.child, identifier);
      if (childChecked) {
        return childChecked;
      }
    }
  }
  return false;
}

export const getBaseUrl = (role:number) => {
  if(role === getRole["superAdmin"]){
    return "/carriers"
  }
  return "/file/list"

}

// pagination dropdown option
export const getPaginationOptions = (data: any) => {
  return data?.map((x: any) => ({ label: x, value: x }));
};


export const numberOnly = (e: any) => {
  const key = e.keyCode ? e.keyCode : e.which;
  if (
    !(
      [8, 9, 13, 27, 46].indexOf(key) !== -1 ||
      (key === 65 && (e.ctrlKey || e.metaKey)) ||
      (key >= 35 && key <= 40) ||
      (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) ||
      (key >= 96 && key <= 105)
    )
  )
    e.preventDefault();
};

export const numberDecimalOnly = (e: any) => {
  const key = e.keyCode ? e.keyCode : e.which;
  const inputValue = e.target.value;

  if (
    !(
      [8, 9, 13, 27, 46].indexOf(key) !== -1 || // Backspace, Tab, Enter, Escape, Delete
      (key === 65 && (e.ctrlKey || e.metaKey)) || // Ctrl+A or Command+A
      (key >= 35 && key <= 40) || // Home, End, Arrow keys
      (key >= 48 && key <= 57 && !(e.shiftKey || e.altKey)) || // Numbers 0-9
      (key >= 96 && key <= 105) || // Numpad numbers 0-9
      (key === 110 && inputValue.indexOf('.') === -1) || // Numpad decimal point, if no existing decimal
      (key === 190 && inputValue.indexOf('.') === -1) // Decimal point, if no existing decimal
    )
  ) {
    e.preventDefault();
  }
};

export const getFileStatusCode = (status: string) => {
  switch (status) {
    case "Cancelled":
      return 1;
    case "Uploaded":
      return 2;
    case "Failed":
      return 4;
    case "Ingested":
      return 3;
    case "Ingestion in progress":
      return 7;
    case "Analyzed":
      return 5;
    case "Uploading":
      return 6;
    default:
      return 6;
  }
};

export const getFileStatusIcon = (status: number | string) => {
  switch (status) {
    case 1:
      return "cancel.svg";
    case 2:
      return "uploaded.svg";
    case 4:
      return "failedIcon.svg";
    case 3:
      return "ingested.svg";
    case 7:
      return "partiallyIngested.svg";
    case 5:
      return "analyzed.svg";
    case 6:
      return "uploadingIcon.svg";
    case "folder":
      return "folder.svg";
    case "file":
      return "file.svg";
    default:
      return "uploadingIcon.svg";
  }
};

export const getDropDownOptions = (list: any, key: any, value: any) => {
  let options = (list?.length > 0 && list?.map((x: any) => ({ label: capitalizeText(x[key]), value: x[value] }))) || [];
  return [{ label: "All", value: "" }, ...options];
};

export const getfolderPath = (folderPath: any, isfilePath: boolean, name?: string) => {
  if (isfilePath) {
    return folderPath ? `${folderPath}/${name}` : name;
  } else {

    return folderPath ? folderPath : "/";
  }

};

export const downloadFile = async (fileUrl: string, fileName: string) => {
  try {
    // Create a download link with appropriate filename
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', fileName);
    // Trigger the download in the browser
    link.click();
    link.remove();
  } catch (error) {
    toast.error('Error downloading file');
  }
};
