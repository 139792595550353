import state from "./state.json";
export const pageSizeList = [10, 20, 30, 40, 50]
export const listCode = state


export const aboutUsLink = "https://smartfreightcentre.org/en/about-sfc/about-us/"

export const evDashboardLink = process.env.REACT_APP_URL
export const domainUrl = process.env.REACT_APP_DOMAIN_URL

export const getRole = {
    superAdmin: 1,
    admin: 2,
    user: 3
}

export const BidFilterOption = [{
    value: "end_date", label: "Closing date"
},
{
    value: "green_lanes", label: "Green lanes"
},
{
    value: "active_bids", label: "Active bids"
}]

export const rangeOption = {
    STEP: 1,
    MIN: 0,
    MAX: 250,
}

