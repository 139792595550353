import { FormGroup, Label, Input } from "reactstrap";
const InputField = ({ type, className = "", name, Id, placeholder, label, onChange, value, onKeyDown, disabled, checked, onClick, testid, autoComplete, maxLength, isRequired = false }: any) => {
    return (
        <FormGroup>
            <Label for={Id}>{label}{isRequired && <span className='text-danger'>*</span>}</Label>
            <Input
                className={className}
                type={type}
                name={name}
                id={Id}
                checked={checked}
                onClick={onClick}
                placeholder={placeholder}
                disabled={disabled}
                data-testid={testid}
                onChange={onChange}
                value={value}
                onKeyDown={onKeyDown}
                autoComplete={autoComplete}
                maxLength={maxLength}
            />
             
        </FormGroup>
    )
}
InputField.defaultProps = {
    disabled: false,
}
export default InputField