import ImageComponent from "../../images"
import SidebarController from './sidebarController';
import { openSidebar } from 'store/common/commonSlice';
import { Accordion } from 'react-bootstrap';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { getBaseUrl } from 'utils';
import { getRole } from "constant";
import ContactUsView from "pages/contactUs/ContactUsView";

const SidebarLayout = () => {
    const {
        loginDetails,
        handleLogout,
        isSidebarOpen,
        dispatch,
        navigate,
        location,
        showContactUs, 
        setShowContactUs
    } = SidebarController()

    return (<>
        <ContactUsView showContactUs={showContactUs} setShowContactUs={setShowContactUs}/>
        <div data-testid="sidebar-layout" className={`mainsidebar ${isSidebarOpen ? "" : "closeSidebar"}`}>
           
            <div className="sidebarLogo">
                <ImageComponent handleOnClick={() => navigate("/")} path="/images/login/greensightLogo.png" className="greensightLogo img-fluid" />
                <ImageComponent handleOnClick={() => dispatch(openSidebar(!isSidebarOpen))} path="/images/hamburgerClose.svg" className={`closedIcon img-fluid ${isSidebarOpen ? "" : "d-none"}`} />
            </div>
            <div className="sidebarnav-wrapper">
                <Accordion>
                    <Nav className="flex-column">
                        {[getRole["superAdmin"]].includes(loginDetails?.data?.role) && <NavItem className="position-relative mb-2">
                            <NavLink to={getBaseUrl(loginDetails?.data?.role)} className={(navData) => (navData.isActive || location?.pathname.includes("file") || location?.pathname?.includes("user-management") ? 'activebar active' : '')}>
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper " data-toggle="tooltip" data-placement="right" title="Carrier Management">
                                    <ImageComponent path="/images/sidebar/dashboardicon.svg" />
                                    <h4 className="mb-0 navText pe-0">Carrier Management</h4>
                                </div>
                            </NavLink>
                        </NavItem>}

                        {[getRole["admin"], getRole["user"]].includes(loginDetails?.data?.role) && <NavItem className="position-relative mb-2">
                            <NavLink to={getBaseUrl(loginDetails?.data?.role)} className={(navData) => (navData.isActive || location?.pathname.includes("file-management") ? 'activebar active' : '')}>
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Data management">
                                    <ImageComponent path="/images/sidebar/dataManagement.svg" />
                                    <h4 className="mb-0 navText">Data Management</h4>
                                </div>
                            </NavLink>
                        </NavItem>
                        }

                        {[getRole["admin"]].includes(loginDetails?.data?.role) && <NavItem className="position-relative mb-2">
                            <NavLink to="/user-management" className={(navData) => (navData.isActive || location?.pathname?.includes("user-management") ? 'activebar active' : '')}>
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper " data-toggle="tooltip" data-placement="right" title="User Management">
                                    <ImageComponent path="/images/sidebar/userManagement.svg" />
                                    <h4 className="mb-0 navText pe-0">User Management</h4>
                                </div>
                            </NavLink>
                        </NavItem>}

                        <NavItem className="position-relative mb-2">
                            <NavLink to={"/settings"} className={(navData) => (navData.isActive ? 'activebar active' : '')}>
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="User Settings">
                                    <ImageComponent path="/images/sidebar/setting.svg" />
                                    <h4 className="mb-0 navText">User Settings</h4>
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem className="position-relative mb-2">
                            <NavLink to={"/terms"} className={(navData) => (navData.isActive ? 'activebar active' : '')}>
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Terms and Conditions">
                                    <ImageComponent path="/images/sidebar/terms.svg" />
                                    <h4 className="mb-0 navText">Terms & Conditions</h4>
                                </div>
                            </NavLink>
                        </NavItem>
                        {![getRole["superAdmin"]].includes(loginDetails?.data?.role) && <NavItem className="position-relative mb-2">
                            <NavLink to={"#"} className={(navData) => (showContactUs ? 'activebar active' : '')}>
                                <div className="d-flex align-items-center gap-2 navitemtxtWrapper" data-toggle="tooltip" data-placement="right" title="Contact Us" onClick={() => setShowContactUs(!showContactUs)}>
                                    <ImageComponent path="/images/sidebar/contact-us.svg" />
                                    <h4 className="mb-0 navText">Contact Us</h4>
                                </div>
                            </NavLink>
                        </NavItem>}
                        <NavItem>
                            <NavLink to={'/'}>
                                <div className="d-flex align-items-center gap-2 mb-0 navitemtxtWrapper" onClick={handleLogout} data-toggle="tooltip" data-placement="right" title="Logout">
                                    <ImageComponent path="/images/sidebar/login.svg" />
                                    <h4 className="mb-0 navText">Logout</h4>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Accordion>
            </div>
            <div className="scope-wrapper">
                <ImageComponent path="/images/powered-by.svg" className="pe-0" />
            </div>
        </div>
        </>
    )
}

export default SidebarLayout