import axios from 'axios';

// Method for fetching user details.
const contactUs = async (userData:any, token: any): Promise<any> => {
    try {
        // Send a GET request to the "/user-profile" endpoint with the provided token.
        return (await axios.post("/contact/us", userData, token)).data;
    } catch (error: any) {
        // Handle errors and reject the promise with the error.
        return Promise.reject(error);
    }
};


const commonService = {
    contactUs
};

export default commonService;