import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CommonState } from "./commonDataInterface";
import commonService from "./commonService";
import { getErrorMessage, getTokenHeader } from "utils";
import { toast } from "react-toastify";

/**
 * Redux Slice for common data and functionality
 */

// Define the shape of the state

// Initial state
const initialState: CommonState = {
    isSuccess: false,
    isLoading: false,
    isLoadingProjectCount: false,
    message: null,
    pageTitle: null,
    isSidebarOpen: true
}

// Async Thunk to toggle sidebar status
export const updatePageTitle = createAsyncThunk("page/title", async (name: string) => {
    return name;
})
export const openSidebar = createAsyncThunk("sidebar/open/close", (isOpen: boolean) => {
    return isOpen
})

export const contactUs = createAsyncThunk("contact/us", async (userData:any, thunkApi) => {
    try {
        let res = await commonService.contactUs(userData.payload, getTokenHeader());
        toast.success("Message sent successfully")
        userData?.setShowContactUs(false)
        userData?.formik.resetForm();
        return res
    } catch (error: any) {
        const message: any = getErrorMessage(error);
        return thunkApi.rejectWithValue(message);
    }
 }
);

// Define the common data reducer
export const commonReducer = createSlice({
    name: "common-data",
    initialState,
    reducers: {
        resetCommonData: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(updatePageTitle.fulfilled, (state, action) => {
                state.pageTitle = action.payload;
            })
            .addCase(openSidebar.fulfilled, (state, action) => {
                state.isSidebarOpen = action.payload;
            }).addCase(contactUs.pending, (state) => {
                state.isSuccess = false;
                state.isLoading = true;
              })
              .addCase(contactUs.fulfilled, (state, action) => {
                state.isSuccess = true;
                state.isLoading = false;
              })
              .addCase(contactUs.rejected, (state, action) => {
                state.isSuccess = false;
                state.isLoading = false;
              })
    }
})

// Export actions and reducer
export const { resetCommonData } = commonReducer.actions;
export default commonReducer.reducer;
